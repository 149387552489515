<template>
    <div>
        <v-main fluid class="w-100">
            <div v-show="GetWhatToShow == 'view'" class="w-100 pa-8">
                <kview :form="'irsaliye'"
                       :viewname="viewname"
                       @rowclicked="ViewClicked($event, 'view')"></kview>
            </div>

            <div class="w-100 pa-8" v-show="GetWhatToShow == 'form'">
                <v-toolbar dense>
                    <v-btn small icon @click="BackToView">
                        <v-icon>arrow_back</v-icon>
                    </v-btn>
                    <v-toolbar-title>Sabit Varlık Tanımlama</v-toolbar-title>
                    <v-spacer></v-spacer>

                </v-toolbar>
                <v-form ref="form"
                        v-model="valid"
                        :lazy-validation="lazy"
                        class="w-100">
                    <v-container fluid>
                        <p>Srtatatat</p>
                        <p v-text="GetState"></p>
                        <dynoform header="Varlık Tanımlama"
                                  @change="UpdateFormField($event, 'formdata')"
                                  @changeGrid="UpdateExtData($event, 'formdata')"
                                  @click="FormButtonClicked($event)"
                                  :attributes="GetFormAttributes"
                                  @SearchInputChanged="SearchInputChanged($event, 'formdata')"
                                  :tabname="Tab-1"></dynoform>
                    </v-container>
                    <v-container fluid>
                        <v-row justify="center">
                            <v-dialog class="col-xs-6"
                                      v-model="actionResultDialog"
                                      persistent
                                      max-width="290">
                                <v-card>
                                    <v-card-title class="headline">İşleminiz tamamlandı</v-card-title>
                                    <v-card-text v-if="GetFormMessages.errorMessage == ''">İşlem Başarılı</v-card-text>
                                    <v-card-text v-else>
                                        {{ GetFormMessages.errorMessage }}
                                    </v-card-text>
                                    <v-card-text v-if="GetFormMessages.infoMessage != ''">
                                        {{ GetFormMessages.infoMessage }}
                                    </v-card-text>
                                    <v-card-actions>
                                        <v-spacer></v-spacer>
                                        <v-btn color="red darken-1"
                                               text
                                               @click="CloseActionResultDialog()">Kapat</v-btn>
                                    </v-card-actions>
                                </v-card>
                            </v-dialog>
                        </v-row>
                    </v-container>
                </v-form>
            </div>
            <!--<v-container fluid>
            <textarea cols="100" rows="10">{{ GetState}}</textarea>
        </v-container>-->
            <v-footer class="primary darken-4" inset app>

                <v-btn v-if="!actionBarWait"
                       v-for="action in GetFormAction"
                       @click="FormAction(action)"
                       :style="GetActionButtonStyle(action)"
                       :key="action.action">
                    {{ action.label }}
                </v-btn>
                <v-toolbar-title v-if="actionBarWait" class="white--text">Lütfen Bekleyiniz</v-toolbar-title>
                <v-spacer class="pl-2"> </v-spacer>
                <v-toolbar-title class="white--text">SEY</v-toolbar-title>
            </v-footer>
        </v-main>

    </div>
</template>
<script>
    import Vue from 'vue';
    import Vuex from 'vuex';
    Vue.use(Vuex);
    import Utilities from '@/Utilities.js'


    // Hareket logları
    var Inventory_Asset_Transactions = {
        tableName: 'Inventory_Asset_Transactions',
        header: 'Hareket Tarihçesi',
        columns: {
            rowid: {
                type: 'text',
                label: 'Sıra',
                edit: '',
                name: 'rowid',
                width: 10
            },

            TransactionEmployeeName: {
                type: 'text',
                label: 'İşlemi Yapan Çalışan',
                edit: '',
                name: 'ItemAdded',

            },

            TransactionDate: {
                type: 'date',
                label: 'İşlemi Tarihi',
                edit: '',
                name: 'TransactionDate',
                width: 140
            },
            talepno: {
                type: 'text',
                label: 'Talep No',
                edit: '',
                name: 'talepno',
            },
            FromLocationName: {
                type: 'text',
                label: 'Çıkış Lokasyonu',
                edit: '',
                name: 'FromLocationName',

            },
            ToLocationName: {
                type: 'text',
                label: 'Hedef Lokasyonu',
                edit: '',
                name: 'ToLocationName',
            },

            ToEmployeeName: {
                type: 'text',
                label: 'Sorumlu Çalışan',
                edit: '',
                name: 'ToEmployeeName',

            },

            Note: {
                type: 'text',
                label: 'FL_Note',
                edit: '',
                name: 'Note',
            },
            formid: {
                type: 'hidden',
                label: '',
                edit: '',
                name: 'formid',
                width: 0
            }
        },
        extdatainfo: {
            id: 'id', //Parent table id
            ident: 'formid', //child id or foreinkey
            formField1: '',
            formFieldValue1: '',
            formField2: '',
            formFieldValue2: '',
            xtableName: '',
            queryName: 'Asset_Transactions'
        },
        parameters: ['**'],
        data: [],
        loaded: false,
        loading: false
    };


    // END Hareket logları
    var Inventory_Asset_formInfo = {
        tabs: [
            { order: 1, header: 'MALZEME TANIMI' },
            { order: 2, header: 'MALİYET' },
            { order: 3, header: 'BBDK' },
            { order: 40, header: 'VUK' },
            { order: 50, header: 'LOKASYON' },
            { order: 60, header: 'TARİHÇE' },

        ],
        TabAttributes: {
            "1": { show: true },
            "2": { show: true },
            "3": { show: true },
            "40": { show: true },
            "50": { show: true },
            "60": { show: true },

        },
        groups: [
            {
                tab: 1,
                order: 1,
                header: 'Malzeme Bilgileri'
            },
            {
                tab: 2,
                order: 2,
                header: 'Maliyet Bilgileri'
            },
            {
                tab: 3,
                order: 3,
                header: 'BDDK Amortisman Bilgileri'
            },
            {
                tab: 40,
                order: 40,
                header: 'VUK Amortisman Bilgileri'
            },

            {
                tab: 50,
                order: 50,
                header: 'Lokasyon Bilgileri'
            },
            {
                tab: 60,
                order: 60,
                header: 'İşlem Tarihçesi'
            },

        ],

        Columns: {
            id: {
                type: 'hidden',
                group: 1,
                sira: 110,
                name: 'id',
                label: 'Asset ID',
                edit: ''
            },

            ItemName: {
                type: 'auto',
                group: 1,
                sira: 20,
                name: 'ItemName',
                label: 'Kalem Tanımı',
                edit: '01Q',
                url: 'api/LOV.ashx',
                parameters: ['*GroupName', '*GroupId', '*LocationId', '*LocationName'],
                outputs: {
                    id: 'ItemId',
                    ItemName: 'ItemName',
                    ItemCode: 'ItemCode',
                    GroupId: 'GroupId',
                    GroupName: 'GroupName',
                    ConcatenatedGroupName: 'ConcatenatedGroupName',
                    DepreciationDuration: 'DepreciationDuration',
                    DepreciationDurationVUK: 'DepreciationDurationVUK',
                    DepreciationType: 'DepreciationType',
                    DepreciationTypeBDDK: 'DepreciationTypeBDDK',
                    DepreciationDurationTypeBDDK: 'DepreciationDurationTypeBDDK',
                    DepreciationRate: 'DepreciationRate',
                    DepreciationDurationType: 'DepreciationDurationType',
                    DepreciationDurationTypeVUK: 'DepreciationDurationTypeVUK',
                    StockAccount: 'StockAccount',
                    InvestmentAccount: 'InvestmentAccount',
                    ExpenseAccount: 'ExpenseAccount',
                    AccumulatedDepreciationAccount: 'AccumulatedDepreciationAccount',

                },
                itemText: ['ItemName', 'ConcatenatedGroupName'],
                searchText: 'ItemName'
            },
            ItemId: {
                type: 'link',
                group: 1,
                sira: 25,
                name: 'ItemId',
                label: 'Ürün Tanımı',
                edit: '01',
                link: '/alterpi/sey/#/Form/Item?id=',
                validateMin: 0,
                validateMax: 0
            },
            Description:
            {
                type: 'textarea',
                group: 1,
                sira: 27,
                name: 'Description',
                label: 'Varlık Adı',
                edit: '01Q',
                height: 1,
                validateMin: 0,
                validateMax: 128
            },
            GroupName: {
                type: 'auto',
                group: 1,
                sira: 30,
                name: 'GroupName',
                label: 'Bağlı Kategori',
                edit: '01Q',
                url: 'api/LOV.ashx',
                parameters: ['*GroupName', '*GroupId', '*LocationId', '*LocationName'],
                outputs: {
                    id: 'GroupId',
                    GroupName: 'GroupName',
                    ConcatenatedGroupName: 'ConcatenatedGroupName',
                    DepreciationDuration: 'DepreciationDuration',
                    DepreciationDurationVUK: 'DepreciationDurationVUK',
                    DepreciationType: 'DepreciationType',
                    DepreciationTypeBDDK: 'DepreciationTypeBDDK',
                    DepreciationDurationTypeBDDK: 'DepreciationDurationTypeBDDK',
                    DepreciationRate: 'DepreciationRate',
                    DepreciationDurationType: 'DepreciationDurationType',
                    DepreciationDurationTypeVUK: 'DepreciationDurationTypeVUK',
                    StockAccount: 'StockAccount',
                    InvestmentAccount: 'InvestmentAccount',
                    ExpenseAccount: 'ExpenseAccount',
                    AccumulatedDepreciationAccount: 'AccumulatedDepreciationAccount',

                },
                itemText: ['GroupName', 'ConcatenatedGroupName'],
                searchText: 'GroupName'
            },

            GroupId: {
                type: 'link',
                group: 1,
                sira: 35,
                name: 'GroupId',
                label: 'Alt Menkul Tipi Tanımı',
                edit: '',
                link: '/alterpi/sey/#/Form/Group?id=',
                validateMin: 0,
                validateMax: 0
            },

            ParentBarcode: {
                type: 'auto',
                group: 1,
                sira: 40,
                name: 'ParentBarcode',
                label: 'Ana Varlık No',
                edit: '01Q',
                url: 'api/LOV.ashx',
                parameters: [],
                outputs: {
                    Barcode: 'ParentBarcode',
                    id: 'ParentId'
                },
                itemText: ['ItemName', 'Barcode'],
                searchText: 'Barcode'
            },



            AssetBrand: {
                type: 'auto',
                group: 1,
                sira: 87,
                label: 'Marka',
                edit: '012Q',
                name: 'AssetBrand',
                width: 60,
                url: 'api/LOV.ashx',
                parameters: [],
                outputs: { markaad: 'AssetBrand' },
                itemText: ['markaad'],
                searchText: 'markaad'
            },
            AssetModel: {
                type: 'auto',
                group: 1,
                sira: 88,
                name: 'AssetModel',
                label: 'Model',
                edit: '012Q',
                width: 60,
                minLength: 0,
                url: 'api/LOV.ashx',
                parameters: { AssetBrand: '*AssetBrand' },
                outputs: { AssetModel: 'AssetModel' },
                itemText: ['AssetModel'],
                searchText: 'AssetModel'
            },
            AssetType: {
                type: 'text',
                col : '4',
                group: 1,
                sira: 90,
                name: 'AssetType',
                label: 'Tip',
                edit: '012Q',
                validateMin: 0,
                validateMax: 128
            },
            SerialNumber:
            {
                type: 'text',
                group: 1,
                sira: 95,
                name: 'SerialNumber',
                label: 'Seri No',
                edit: '012Q',
                validateMin: 0,
                validateMax: 128
            },
            ConcatenatedGroupName: {
                type: 'textarea',
                group: 1,
                sira: 990,
                name: 'ConcatenatedGroupName',
                label: 'Tam Varlık Kategorisi',
                edit: ''
            },
            Status: {
                type: 'text',
                group: 1,
                sira: 989,
                name: 'Status',
                label: 'FL_Status',
                edit: '01',
                validateMin: 0,
                validateMax: 128
            },
            Note: {
                type: 'textarea',
                group: 1,
                sira: 9999,
                name: 'Note',
                label: 'FL_Comment',
                height: 4,
                edit: '01Q',
                validateMin: 0,
                validateMax: -1
            },


            LocationId: {
                type: 'int',
                group: 4,
                sira: 50,
                name: 'LocationId',
                label: 'Lokasyon ID',
                edit: '01',
                validateMin: 0,
                validateMax: 0
            },


            LocationType: {
                type: 'select',
                group: 50,
                sira: 200,
                name: 'LocationType',
                label: 'Lokasyon Tipi',
                edit: '01Q',
                options: [
                    { text: 'Depo', value: '1' },
                    { text: 'Şube', value: '2' },
                    { text: 'GM Birim', value: '3' },
                    { text: 'Kişi', value: '4' },
                    { text: 'Lab', value: '5' },
                    { text: 'Hibe', value: '6' },
                    { text: 'Kayıp', value: '7' },
                    { text: 'Satış', value: '8' }
                ]
            },
            LocationName: {
                type: 'auto',
                group: 50,
                sira: 205,
                name: 'LocationName',
                label: 'Bulunduğu Lokasyon Adı',
                edit: '01Q',
                url: 'api/LOV.ashx',
                parameters: ['*LocationType'],
                outputs: {
                    id: 'LocationId',
                    LocationName: 'LocationName',
                    LocationCode: 'LocationCode',
                    CostCenterNo: 'CostCenter',
                    // LocationType: 'LocationType'
                },
                itemText: ['LocationName', 'ShownName'],
                searchText: 'LocationName'
            },

            EmployeeName: {
                type: 'auto',
                group: 50,
                sira: 400,
                name: 'EmployeeName',
                label: 'Sorumlu / Zimmetli Kişi Ad',
                edit: '01Q',
                url: 'api/LOV.ashx',
                parameters: [],
                outputs: { AdSoyad: 'EmployeeName', Sicil: 'EmployeeId' },
                itemText: ['AdSoyad', 'Sicil', 'LocationName'],
                searchText: 'AdSoyad'
            },

            EmployeeId: {
                type: 'text',
                group: 50,
                sira: 405,
                name: 'EmployeeId',
                label: 'Sorumlu / Zimmetli Kişi Sicil',
                edit: '',
                validateMin: 0,
                validateMax: 16
            },

            CostCenter: {
                type: 'text',
                group: 50,
                sira: 410,
                name: 'CostCenter',
                label: 'Masraf Merkezi/Şube Kodu',
                edit: '',
                validateMin: 0,
                validateMax: 16
            },
            ExpectedReturnDate:
            {
                type: 'date',
                group: 50,
                name: 'ExpectedReturnDate',
                label: 'Beklenen İade Tarihi',
                edit: '012Q',
                sira: 415
            },

            PurchaseValue: {
                type: 'number',
                group: 2,
                sira: 81,
                name: 'PurchaseValue',
                label: 'İlk Alım Bedeli',
                edit: '01Q',
                validateMin: 0,
                validateMax: 0
            },
            Quantity:
            {
                type: 'number',
                group: 2,
                sira: 105,
                name: 'Quantity',
                label: 'Varlık Adedi',
                edit: 'Q',
                validateMin: 0,
                validateMax: 0
            },
            PurchaseDate: {
                type: 'date',
                group: 2,
                sira: 110,
                name: 'PurchaseDate',
                label: 'Satın Alma Tarihi',
                edit: '01Q',
                validateMin: 0,
                validateMax: 0
            },
            RequestId: {
                type: 'link',
                group: 2,
                sira: 235,
                name: 'RequestId',
                label: 'Talep Form No',
                edit: '01',
                link: '/formart/saltalep/saltalep_frm.aspx?openformid=',
                validateMin: 0,
                validateMax: 0
            },
            PurchaseId: {
                type: 'link',
                group: 2,
                sira: 240,
                name: 'PurchaseId',
                label: 'Sipariş Form No',
                edit: '01',
                link: '/alterpi/siparis/siparis_frm.aspx?openformid='
            },

            WaybillId: {
                type: 'link',
                group: 2,
                sira: 250,
                name: 'WaybillId',
                label: 'İrsaliye Form No',
                edit: '01',
                link: '/alterpi/irsaliye/irsaliye_frm.aspx?openformid='
            },
            WaybillItemId: {
                type: 'int',
                group: 2,
                sira: 260,
                name: 'WaybillItemId',
                label: 'İrsaliye Kalemi',
                edit: ''
            },

            InvoiceId: {
                type: 'link',
                group: 2,
                sira: 270,
                name: 'InvoiceId',
                label: 'Fatura Form No',
                edit: '01',
                link: '/alterpi/fatura/fatura_frm.aspx?openformid='
            },

            Barcode: {
                type: 'text',
                col: '4',
                group: 1,
                sira: 30,
                name: 'Barcode',
                label: 'Varlık No',
                edit: '01',
                validateMin: 1,
                validateMax: 8
            },
            CurrentBarcode: {
                type: 'text',
                col: '4',
                group: 1,
                sira: 31,
                name: 'CurrentBarcode',
                label: 'Güncel Varlık No',
                edit: '012',
                validateMin: 0,
                validateMax: 8
            },

            // BDDK Alanları
            DepreciationValue: {
                type: 'number',
                group: 3,
                sira: 100,
                name: 'DepreciationValue',
                label: 'Cari Maliyet ',
                edit: '012Q',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationStart: {
                type: 'date',
                group: 3,
                sira: 170,
                name: 'DepreciationStart',
                label: 'Amortisman Başlama Tarihi',
                edit: '01Q',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationDuration: {
                type: 'int',
                group: 3,
                sira: 160,
                name: 'DepreciationDuration',
                label: 'Amortisman Süresi',
                edit: '2',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationEnd: {
                type: 'date',
                group: 3,
                sira: 180,
                name: 'DepreciationEnd',
                label: 'Amortisman Son Tarihi',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            Depreciable:
            {
                type: 'select',
                group: 3,
                sira: 185,
                name: 'Depreciable',
                label: 'Amortismana Tabi mi',
                edit: '01Q',
                options: [
                    { text: 'Amortismana Tabi', value: 'Y' },
                    { text: 'Amortismana Tabi Değil', value: 'N' },
                ]
            },
            DepreciationTypeBDDK: {
                type: 'select',
                group: 3,
                sira: 190,
                name: 'DepreciationTypeBDDK',
                label: 'Metod',
                edit: '',
                options: [
                    { text: 'Seçiniz', value: '0' },
                    { text: 'Normal', value: '1' },
                    { text: 'Hızlandırılmış', value: '2' }
                ]
            },

            DepreciationDurationTypeBDDK:
            {
                type: 'select',
                group: 3,
                sira: 195,
                name: 'DepreciationDurationTypeBDDK',
                label: 'Dağıtım Esası',
                edit: '',
                options: [
                    { text: 'Yıllık', value: 'Y' },
                    { text: 'Kıst', value: 'K' },
                ]
            },
            CurrentYearDepreciation: {
                type: 'number',
                group: 3,
                sira: 340,
                name: 'CurrentYearDepreciation',
                label: 'Güncel Sene Amortismanı',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            AccumulatedDepreciation: {
                type: 'number',
                group: 3,
                sira: 350,
                name: 'AccumulatedDepreciation',
                label: 'Birikmiş Amortisman Tutarı',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationMontly: {
                type: 'number',
                group: 3,
                sira: 360,
                name: 'DepreciationMontly',
                label: 'Aylık Amortisman',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            CurrentBookValue: {
                type: 'number',
                group: 3,
                sira: 370,
                name: 'CurrentBookValue',
                label: 'Net Defter Değeri',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            NetValueIncrease:
            {
                type: 'number',
                group: 3,
                sira: 372,
                name: 'NetValueIncrease',
                label: 'Yeniden Değerleme Tutarı',
                edit: '',
                validateMin: 0,
                validateMax: 0

            },
            NetValueIncreaseTax:
            {
                type: 'number',
                group: 3,
                sira: 372,
                name: 'NetValueIncreaseTax',
                label: 'Yeniden Değerleme Vergisi',
                edit: '',
                validateMin: 0,
                validateMax: 0

            },
            NetValueIncreaseBeforeTax:
            {
                type: 'number',
                group: 3,
                sira: 372,
                name: 'NetValueIncreaseBeforeTax',
                label: 'Net Değer Artış',
                edit: '',
                validateMin: 0,
                validateMax: 0,

            },
            // VUK değerleri
            DepreciationValueVUK: {
                type: 'number',
                group: 40,
                sira: 400,
                name: 'DepreciationValueVUK',
                label: 'Cari Maliyet',
                edit: '012Q',
                validateMin: 0,
                validateMax: 0
            },
            DepreciableVUK:
            {
                type: 'select',
                group: 40,
                sira: 403,
                name: 'DepreciableVUK',
                label: 'Amortismana Tabi mi',
                edit: '01Q',
                options: [
                    { text: 'Amortismana Tabi', value: 'Y' },
                    { text: 'Amortismana Tabi Değil', value: 'N' },
                ]
            },
            DepreciationType: {
                type: 'select',
                group: 40,
                sira: 405,
                name: 'DepreciationType',
                label: 'Metod',
                edit: '01Q',
                options: [
                    { text: 'Seçiniz', value: '0' },
                    { text: 'Normal', value: '1' },
                    { text: 'Hızlandırılmış', value: '2' }
                ]
            },
            DepreciationDurationType:
            {
                type: 'select',
                group: 40,
                sira: 407,
                name: 'DepreciationDurationType',
                label: 'Dağıtım Esası',
                edit: '01Q',
                options: [
                    { text: 'Yıllık', value: 'Y' },
                    { text: 'Kıst', value: 'K' },
                ]
            },
            DepreciationStartVUK: {
                type: 'date',
                group: 40,
                sira: 410,
                name: 'DepreciationStartVUK',
                label: 'VUK Amortisman Başlama Tarihi',
                edit: '01Q',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationDurationVUK: {
                type: 'int',
                group: 40,
                sira: 415,
                name: 'DepreciationDurationVUK',
                label: 'VUK Amortisman Süresi',
                edit: '01Q',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationEndVUK: {
                type: 'date',
                group: 40,
                sira: 420,
                name: 'DepreciationEndVUK',
                label: 'Amortisman Son Tarihi',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationMontlyVUK: {
                type: 'number',
                group: 40,
                sira: 435,
                name: 'DepreciationMontlyVUK',
                label: 'VUK Güncel Aylık amortisman',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            CurrentYearDepreciationVUK: {
                type: 'number',
                group: 40,
                sira: 440,
                name: 'CurrentYearDepreciationVUK',
                label: 'Güncel Sene Amortismanı',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },

            CurrentYearDepreciationKKEG:
            {
                type: 'number',
                group: 40,
                sira: 450,
                name: 'CurrentYearDepreciationKKEG',
                label: 'KKEG Yıllık Amortisman Tutarı',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },
            DepreciationMontlyKKEG:
            {
                type: 'number',
                group: 40,
                sira: 450,
                name: 'DepreciationMontlyKKEG',
                label: 'KKEG Cari Ay Amortisman Tutarı',
                edit: '',
                validateMin: 0,
                validateMax: 0
            },


            Transactions: {
                type: 'grid',
                group: 60,
                sira: 20,
                name: 'Inventory_Asset_Transactions',
                label: 'Varlık Haraketleri',
                edit: '',
                height: 16
            },
            history: {
                type: 'textarea',
                group: 60,
                sira: 30,
                name: 'history',
                label: 'Tarihçe',
                edit: ''
            },

            PriceSold: {
                type: 'number',
                group: 70,
                sira: 30,
                name: 'PriceSold',
                label: 'Satış Fiyatı',
                edit: '01'
            },
            TransferMethod: {
                type: 'select',
                group: 70,
                sira: 40,
                name: 'TransferMethod',
                label: 'EFT/HAVALE',
                edit: '01',
                options: [
                    { text: 'Seçiniz', value: '' },
                    { text: 'EFT', value: 'EFT' },
                    { text: 'HAVALE', value: 'HAVALE' },
                ]
            },
            Credit: {
                type: 'number',
                group: 70,
                sira: 50,
                name: 'Credit',
                label: 'Kullanılan Kredi tutarı',
                edit: '01'
            },
            DateSold: {
                type: 'date',
                group: 70,
                sira: 60,
                name: 'DateSold',
                label: 'Satış Tarihi',
                edit: '01'
            },
            Depreciations: {
                type: 'grid',
                group: 80,
                sira: 20,
                name: 'Inventory_Depreciation_List',
                label: 'Amortisman Haraketleri',
                edit: '',
                height: 16
            }
        },
        form: 'irsaliye',
        formid: 0,
        viewName: 'tumu',
        viewparameter: '',

        files: [],
        extdata: [Inventory_Asset_Transactions,],
        actions: [],
        parameters: [],
        attributes: {}
    };

    import { mapGetters } from 'vuex';
    import dynoform from '@/components/DynoForm.vue';
    import grid from '@/components/grid.vue';
    import leftmenu from '@/components/leftmenu.vue';
    import kview from '@/components/view.vue';
    import axios from 'axios';
    import _ from 'lodash';
    import 'material-design-icons-iconfont/dist/material-design-icons.css';
    import { FormMixIn } from '@/MixIns/FormMixins';
    import store from '@/store';
    import moment from 'moment';

    import showdialog from '@/components/ShowDialog.vue';
    Vue.component('showdialog', showdialog);

    function ad_AE(payload) {
        /*alert('AE')*/
    }

    export default {
        name: 'sertifika',
        mixins: [FormMixIn],
        components: {
            dynoform,
            grid,
            leftmenu,
            kview
        },
        props: {
            //form: String,
            formid: [Number, String],
            view: { type: String, default: 'tumu' },
            drawer: { type: Boolean, default: true },
            single: { type: Boolean, default: false },
        },
        data: function () {
            return {
                valid: false,
                messages: { messageType: '', messageBody: '', infoBody: '', viewType: '' },
                lazy: true,
                viewname: 'tumu',
                form: 'irsaliye',
                validateError: '',
                username: '',
                formname: 'ASSET',
                depouser: false,
                accountuser: false,
                propertyeditstates: '',
            };
        },

        methods: {

            FormLoaded: function () {
                Utilities.log('FORM LOADED');
                var formdata = this.GetFormData;
                var groupName = formdata.GroupName;
                if (formdata.state != "0" || formdata.state != "1") {
                    var payload = {};
                    payload.DepreciationTypeBDDK = "1";;
                    payload.DepreciationDurationTypeBDDK = "K";
                    this.$store.commit('SetFields', payload);
                }
                if (formdata.state != "0" && formdata.state != "" && formdata.state != "1") {
                    var self = this;
                    var payload = {};
                    payload.form = 'irsaliye';
                    payload.formid = formdata.id;
                    payload.parameter = formdata.Barcode;
                    this.$store.dispatch('GetFormRoles', payload)
                        .then((response) => {

                            Utilities.log("formloaded GetFormRoles ", response, this.accountuser, this.depouser);

                            var aroles = response.data.list[0].AccountingRoles;
                            var droles = response.data.list[0].DepoRoles;
                            if (aroles != "") self.accountuser = true;
                            if (droles != "") self.depouser = true;
                            //var accountingFields = "DepreciationValueDepreciationStart,DepreciationDuration,DepreciationEnd";
                            //accountingFields = accountingFields.split(",");
                            var accountingFields = "DepreciationTypeBDDK,DepreciationValue,DepreciationStart,DepreciationDuration,DepreciationEnd,DepreciationValueVUK,DepreciationType,DepreciationDurationVUK".split(",");
                            var depoFields = "CurrentBarcode,AssetBrand,AssetModel,AssetType,SerialNumber".split(",");

                            if (formdata.state != '0' && !self.accountuser) {
                                for (var k = 0; k < accountingFields.length; k++) {
                                    self.SetFieldReadOnly(accountingFields[k]);
                                    self.SetFieldDisabled(accountingFields[k]);
                                }
                            }

                            if (formdata.state != '0' && !self.depouser) {
                                for (var k = 0; k < depoFields.length; k++) {
                                    self.SetFieldReadOnly(depoFields[k]);
                                    self.SetFieldDisabled(depoFields[k]);
                                }
                            }
                            if (formdata.state == '0' || (self.depouser || self.accountuser))
                                self.propertyeditstates = '012'
                            else
                                self.propertyeditstates = ''
                        })
                }
                else {
                    this.propertyeditstates = '012'
                }
            },

            Inventory_Asset_data_AL: function () {
                Utilities.log('In Inventory_Asset_data');
            },
            FormButtonClicked(col) {
                var name = col.name;
                if (name == 'ShowRequest') {
                    //Request formunu göster
                    var url =
                        '/alterpi/saltalep/saltalep_frm.aspx?openformid=' +
                        this.GetFormField('RequestId');
                    window.open(url);
                }
            },
            RowClicked: function (rowdata, table) {
                Utilities.log('clicked on table:', table, ' Row:', rowdata);
            },
            RowButtonClicked: function (payload, tableName) {
                Utilities.log(
                    ' Inventory Items RowButtonClicked',
                    payload,
                    ' Table:',
                    tableName
                );
                var button = payload.button.label;
                var id = payload.item.rowid;
                Utilities.log('Button on row:' + id + ' action:' + button);
                if (button == 'Sil')
                    this.$store.commit('RemoveRowFromExtData', {
                        tableName: tableName,
                        rowid: id
                    });
            },

            DepreciationActive_Field_Changed: function (val) {
                Utilities.log(
                    'In Inventory_Locations SerialnoFollowType_Field_Changed',
                    val
                );
                if (val.selectedObject != null && val.selectedObject != '')
                    this.ClearFieldError('DepreciationActive');
            },

            NeedApprove: function (action) {
                var res;
                switch (action) {
                    case "Gericek":
                        res = '';
                        break;
                    case "Iptal":
                        res = this.$root.$i18n.t('DM_CancelForm');
                        break;
                    default:
                        res = '';
                }
                return res;
            },


            ValidateForm(action) {
                this.ClearFieldErrors();
               
                var err = '';
                var errors = [];

                //Form alanlarını kontrol et
                var columns = this.GetFormColumns;
                var formdata = this.GetFormData;

                for (var c in columns) {
                    var value = formdata[c] || '';
                    var err = this.ValidateField(value, columns[c]);
                    if (err != '') {
                        errors.push(err);
                        this.SetFieldError(c);
                    }
                }

                if (formdata.ItemName == '' || formdata.ItemName == null) {
                    errors.push('Ürün Adı alanı seçili olmalıdır');
                    this.SetFieldError('ItemName');
                }

                //if (
                //	formdata.DepreciationActive == '' ||
                //	formdata.DepreciationActive == null
                //) {
                //	errors.push('Amortismana Tabi alanı seçili olmalıdır');
                //	this.SetFieldError('DepreciationActive');
                //}


                if (errors.length > 0) {
                    return errors;
                }
                return '';
            },

            Search: function (event) {
                // var tableName = event.tableName;
                // var id = event.id;
                // var fieldName = event.fieldName;
                // var value = event.value;
                // search parametrelerine göre ilgili servisi çağırarak search işlemi burada yapılacak
                // if( tableName == 'formdata' && fieldName == 'adsoyad')
                //   FetchUserNames(value,{uniteno:this.GetFormField('uniteno'), subeno:GetFormField('subeno')});
                // else if ( tableName == 'Extdata1' && fieldName == 'doviz')
                //   FetchDovizler(value);
            },

            LocationType_Field_Changed: function (vals) {
                //LocationName ve LocationId alanlarıı temizle
                if (this.GetFormField('LocationType') == 4)
                    // Kişi seçilecek. Lokasyon seçmeyi disable et
                    this.SetFieldDisabled('LocationName');
                else {
                    this.ClearFieldDisabled('LocationName');
                    var payload = {};
                    payload.LocationId = 0;
                    payload.LocationName = '';
                    payload.EmployeeId = '';
                    payload.EmployeeName = '';
                    this.$store.commit('SetFields', payload);
                }
            },
            EmployeeName_Field_Changed(val) {
                Utilities.log('EmployeeName_Field_Changed', val);
                var destLoc = this.GetFormField('LocationType');
                var payload = {};
                var subeno = parseInt(val.selectedObject.value['subeno']);
                if (
                    subeno > 0 &&
                    (!destLoc || destLoc == '' || '023'.indexOf(destLoc) >= 0)
                ) {
                    if (subeno == 1) payload.LocationType = '3';
                    else payload.LocationType = '2';
                }
                payload.LocationName = val.selectedObject.value['CostCenterName'];

                this.$store.commit('SetFields', payload);
            },
            LocationName_Field_Changed: function (vals) {
                //LocationName ve LocationId alanlarıı temizle
                Utilities.log('LocationNameChanged', vals);
                var locationType = this.GetFormField('LocationType');
                var locationCode = this.GetFormField('LocationCode');
                if (locationType == 4 && locationCode != "") {
                    // Kişi tipi lokasyonlarda tip değiştirme
                    return;
                }
                var payload = {};
                payload.LocationType = vals.selectedObject.value.LocationType;
                this.$store.commit('SetFields', payload);
            },

            ItemName_Field_Changed: function (vals) {
                Utilities.log(
                    'ItemName_Field_Changed',
                    vals,
                    ' ItemId',
                    this.GetState.formdata.ItemId
                );
                var itemName = vals.fieldValue;
                if (itemName == "") {
                    var payload = {};
                    payload.ItemId = '';
                    payload.ItemCode = '';
                    payload.GroupId = '';
                    payload.GroupName = '';
                    payload.ConcatenatedGroupName = '';
                    payload.DepreciationDuration = '0';
                    payload.DepreciationDurationVUK = '0';
                    payload.DepreciationType = '0';
                    payload.DepreciationRate = '0';
                    payload.DepreciationDurationType = '';
                    payload.DepreciationDurationTypeVUK = '';
                    this.$store.commit("SetFields", payload);

                }


            },
            UpdateFormParameter: function (parameter) {
                this.$store.commit('SetParameter', parameter);
            },

            DepreciationDurationVUK_Field_Changed(vals) {
                this.AmortismanHesaplamalariVUK();
            },
            DepreciationType_Field_Changed(vals) {
                this.AmortismanHesaplamalariVUK();
            },
            DepreciationValueVUK_Field_Changed(vals) {
                //this.AmortismanHesaplamalariVUK();
            },
            DepreciationStartVUK_Field_Changed(vals) {
                this.AmortismanHesaplamalariVUK();
            },
            AmortismanHesaplamalariVUK() {
                var formdata = this.GetState.formdata;
                var type = formdata['DepreciationType'];
                var sure = formdata['DepreciationDurationVUK'];
                var tarih = formdata['DepreciationStartVUK'];
                var bedel = formdata['DepreciationValueVUK'];
                if (type != null && sure != null && tarih != null) {
                    if (sure % 12 != 0) {
                        alert('Amortisman süresi 12 nin katları olmalı');
                        return false;
                    }
                    var oran = 1.0 / (sure / 12.0);
                    if (type == 2) oran *= 2;
                    var yillikAmortisman = 0;
                    if (bedel != null) yillikAmortisman = bedel * oran;

                    var p = {};
                    p.DepreciationMontlyVUK = yillikAmortisman / 12;
                    p.DepreciationEndVUK = moment(moment(tarih).add(sure, 'M'))
                        .endOf('month')
                        .format('YYYY-MM-DD');
                    p.DepreciationRate = oran * 100;
                    this.$store.commit('SetFields', p);
                }
            },
            DepreciationDuration_Field_Changed() {
                this.AmortismanHesaplamalari();
            },
            DepreciationValue_Field_Changed() {
                this.AmortismanHesaplamalari();
            },
            DepreciationStart_Field_Changed() {
                this.AmortismanHesaplamalari();
            },
            AmortismanHesaplamalari() {
                var formdata = this.GetState.formdata;
                var bedel = formdata['DepreciationValue'];
                var sure = formdata['DepreciationDuration'];
                var tarih = formdata['DepreciationStart'];
                if (bedel > 0 && sure > 0 && tarih != '') {
                    if (sure % 12 != 0) {
                        alert('Amortisman süresi 12 nin katları olmalı');
                        return false;
                    }
                    var oran = 1.0 / (sure / 12.0);
                    var yillikAmortisman = bedel * oran;
                    var p = {};
                    p.DepreciationMontly = yillikAmortisman / 12;
                    p.DepreciationEnd = moment(moment(tarih).add(sure, 'M'))
                        .endOf('month')
                        .format('YYYY-MM-DD');
                    p.DepreciationRate = oran * 100;
                    this.$store.commit('SetFields', p);
                }
            }
        },

        created: function () {
            Utilities.log('CREATED ASSET Form', this.$route);

            var query = this.$route.query;
            if (typeof query.id != "undefined") {
                this.$store.commit('SetWhatToShow', { show: 'form' });
                this.viewname = '';
                Utilities.log('WHATTOSHOW ASSET Form', this.GetWhatToShow);
            }
            var columns = Inventory_Asset_formInfo.Columns;
            var attributes = {};
            for (var key in columns) {
                if (columns.hasOwnProperty(key)) {
                    attributes[key] = {
                        items: [],
                        readonly: false,
                        hide: '',
                        error: false,
                        disabled: false
                    };
                }
            }
            Inventory_Asset_formInfo.attributes = attributes;
        },
        mounted: function () {
            Utilities.log(
                'Inventory_Asset LOADED form:',
                this.GetForm,
                this.GetFormId,
                this.GetViewName,
                this.$route,
                this.single,
            );
            this.viewname = this.view;
            this.$store.commit('updateFormInfo', Inventory_Asset_formInfo);
            var query = this.$route.query;
            if (_.isEmpty(query)) {
                // Simple route. Show default view ( "tumu" )
                Utilities.log('MOUNTED Route SHOW default view "tumu"');
                this.viewname = 'tumu';
                this.$store.commit('UpdateViewName', { viewName: "tumu" });
                this.$store.commit('SetWhatToShow', { show: 'view' });
            }
            else {
                //Show given form if id parameter is given
                if (typeof query.id != "undefined") {
                    Utilities.log('MOUNTED Route SHOW form id', query.id);
                    this.$store.commit('SetFormAndFormId', {
                        form: this.form,
                        formid: parseInt(query.id)
                    });
                    this.$store.commit('SetWhatToShow', { show: 'form' });
                    this.viewname = '';
                    this.LoadFormData(query.id);
                }
                else {
                    //view varsa onu göster
                    if (typeof query.view != "undefined") {
                        this.viewname = query.view;
                        Utilities.log('MOUNTED Route SHOW  view ', query.view);
                        this.$store.commit('UpdateViewName', { viewName: query.view });

                    }
                    else {
                        // Hatalı query. default view'e göster
                        this.viewname = "tumu";
                        Utilities.log('MOUNTED Route SHOW hatalı view "tumu"');
                        this.$store.commit('UpdateViewName', { viewName: "tumu" });

                    }
                    this.$store.commit('SetWhatToShow', { show: 'view' });
                }

            }
            return;
        },
        computed: {
            GetState()
            {
                return this.$store.getters.GetFormState;
            },
            FormAttributes: function () {
                return this.GetFormAttributes;
            },
            UserName() {
                return this.GetUserName;
            },
            FormLoadingStatus: {
                get: function () {
                    return this.GetFormLoadingStatus;
                }
            }
        },
        watch: {

            $route: function (nval, oval) {
                Utilities.log('WATCH Route asset:', nval, oval);
                var query = this.$route.query;
                if (_.isEmpty(query)) {
                    // Simple route. Show default view ( "tumu" )
                    Utilities.log('WATCH Route SHOW default view "tumu"');
                    this.viewname = 'tumu';
                    this.$store.commit('UpdateViewName', { viewName: "tumu" });
                    this.$store.commit('SetWhatToShow', { show: 'view' });
                }
                else {
                    //Show given form if id parameter is given
                    if (typeof query.id != "undefined") {
                        Utilities.log('WATCH Route SHOW form id', query.id);
                        this.$store.commit('SetFormAndFormId', {
                            form: this.form,
                            formid: parseInt(query.id)
                        });
                        this.$store.commit('SetWhatToShow', { show: 'form' });
                        this.LoadFormData(query.id);
                    }
                    else {
                        //view varsa onu göster
                        if (typeof query.view != "undefined") {
                            this.viewname = query.view;
                            Utilities.log('WATCH Route SHOW  view ', query.view);
                            this.$store.commit('UpdateViewName', { viewName: query.view });

                        }
                        else {
                            // Hatalı query. default view'e göster
                            this.viewname = "tumu";
                            Utilities.log('WATCH Route SHOW hatalı view "tumu"');
                            this.$store.commit('UpdateViewName', { viewName: "tumu" });

                        }
                        this.$store.commit('SetWhatToShow', { show: 'view' });
                    }
                }
            },

            FormLoadingStatus: {
                handler: function (val, oldVal) {
                    Utilities.log('FormLoadingStatus changed');
                },
                deep: true
            },
            FormAttributes: function (nval, oldval) {
                Utilities.log('Watch formattribs:', nval, oldval);
            },
            ItemName: function (nval, oldval) {
                Utilities.log('Watch formattribs ItemName:', nval, oldval);
            },

            drawer: function (n, o) {
                Utilities.log("WATCH drawer:", n, o);
            },

            formid: function (nval, oval) {
                Utilities.log("Watch FormId new:", nval, " old:", oval);
                this.LoadFormData(nval);
            },

            view: function (nval, oval) {
                Utilities.log("Watch view new:", nval, " old:", oval);
                this.LoadFormData(nval);
            },

        }
    };
</script>
<style>


    #app {
        font-family: 'Avenir', Helvetica, Arial, sans-serif;
        -webkit-font-smoothing: antialiased;
        -moz-osx-font-smoothing: grayscale;
        text-align: left;
        color: #2c3e50;
        display: flex;
        flex-direction: column;
        height: 100%;
    }

    .content {
        flex: 1 0 auto;
        /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
        padding: 20px;
    }

    .footer {
        flex-shrink: 0;
        /* Prevent Chrome, Opera, and Safari from letting these items shrink to smaller than their content's default minimum size. */
        padding: 20px;
    }
</style>
